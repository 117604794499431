const initFormValidation = () => {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  let forms = document.getElementsByClassName('needs-validation');
  // Loop over them and prevent submission
  let validation = Array.prototype.filter.call(forms, function (form) {
    form.addEventListener('submit', function (event) {
      // event.preventDefault();
      // event.stopPropagation();
      // set submit loading
      let submit = this.querySelector('button[type="submit"]');
      if (submit) {
        submit.disabled = true;
      }

      // console.log('submitting', form.checkValidity());

      if (form.checkValidity() === false /*|| phoneInputsCheck(form) === false*/) {
        event.preventDefault();
        event.stopPropagation();
        if (submit) {
          submit.disabled = false;
        }
        // check if in modal
        const firstInputInvalid = form.querySelector(':invalid');
        if (firstInputInvalid) {
          firstInputInvalid.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
      else if (form.classList.contains('recaptcha-check')) {
        if(!form.classList.contains('need-checks') || (form.classList.contains('need-checks') && form.classList.contains('checks-validated'))){
          event.preventDefault();
          event.stopPropagation();

          // if grecaptcha is defined, then it means recaptcha has been loaded by CDN because the browser doesn't support dynamic import
          if(typeof grecaptcha !== 'undefined'){
            grecaptcha.ready(function () {
              grecaptcha.execute('6LeKXLAUAAAAAOedux0FrWKsvr9FHDRoKU1FlEzO', { action: 'homepage' }).then(function (token) {
                linkedInTracking(form.id);

                recaptchaSuccess(token, form);
              }, function (error) {
                recaptchaError(error, submit);
              });
            });
          }
          //if grecaptcha is not defined, then it means dynamic import is available so we use it
          else {
            import('recaptcha-v3').then(grecaptcha => {
              // check google recaptcha
              grecaptcha.load('6LeKXLAUAAAAAOedux0FrWKsvr9FHDRoKU1FlEzO').then((recaptcha) => {
                recaptcha.execute('homepage').then(function (token) {
                  linkedInTracking(form.id);

                  recaptchaSuccess(token, form);
                }, function (error) {
                  recaptchaError(error, submit);
                });
              });
            });
          }
        }
      }

      form.classList.add('was-validated');
      // return false;
    }, false);
  });
}

const recaptchaSuccess = (token, form) => {
  let inputHidden = document.createElement('input');
  inputHidden.type = 'hidden';
  inputHidden.value = token;
  inputHidden.name = 'g-recaptcha-response';
  form.appendChild(inputHidden);

  if(!form.classList.contains('ajax-form'))
    form.submit();
  else
    form.classList.add('recaptcha-done');
}

const recaptchaError = (error, submit) => {
  console.warn(error);
  submit.disabled = false;
}

const linkedInTracking = (formId) => {
  if(typeof window.lintrk !== 'undefined'){
    if(formId === 'btob-gift'){
      window.lintrk('track', { conversion_id: 7303961 }); //GC Linkedin Tracking
    }
    else if(formId === 'btob'){
      window.lintrk('track', { conversion_id: 7303953 }); //B2B Corporate page
    }
  }
}

export { initFormValidation }


