const initMobileAroundMe = () => {
  //ONLY FOR HOME
  const mobileBarArroundMe = document.querySelector('.mobile-fixed-bar-arround-me');
  if(mobileBarArroundMe) {
    const submitBtn = mobileBarArroundMe.querySelector('button');
    submitBtn.addEventListener('click', function() {
      // console.log('click get position', navigator.geolocation);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (pos) {
          console.log(pos);
          mobileBarArroundMe.querySelector('input[name=lat]').value = pos.coords.latitude;
          mobileBarArroundMe.querySelector('input[name=lng]').value = pos.coords.longitude;

          // then submit form
          mobileBarArroundMe.querySelector('form').submit();
        }, function(err) {
          console.error(err);
        }, {
          timeout: 5000
        });
      } else {
        console.warn('No geolocation available');
      }
    })
  }
}

export { initMobileAroundMe }
