const initHomeSeoBlock = () => {
  const buttons = document.querySelectorAll('#members-love-block .view-all-items');
  if(buttons){
    buttons.forEach(button => {
      const wrapper = button.closest('.content-wrapper');
      if(wrapper){
        button.addEventListener('click', () => {
          const hiddenItems = wrapper.querySelectorAll('.item-wrapper.hidden');
          if(hiddenItems){
            hiddenItems.forEach(item => {
              item.classList.toggle('hidden');
            });
          }

          button.remove();
        });
      }
    });
  }

  //for seo, set hidden in JS so googlebot reads it easily ?
  const setHiddenElts = document.querySelectorAll('#members-love-block .set-hidden');
  if(setHiddenElts){
    setHiddenElts.forEach(elt => {
      elt.classList.add('hidden');
    });
  }
}

export { initHomeSeoBlock }
