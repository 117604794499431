import Swiper, { Pagination, Navigation, Autoplay, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { handleExperienceGalleryItemClicked } from '../frontv2/Search/swiper-utils';

const initSwiper = (wrapper = document, options = {}) => {
  const swiperDivs = wrapper.querySelectorAll('.swiper');
  if (swiperDivs) {
    swiperDivs.forEach(swiperDiv => {
      const swiper = new Swiper(swiperDiv, {
        modules: [Pagination, Navigation, Autoplay, Mousewheel],
        // Navigation arrows
        navigation: {
          nextEl: swiperDiv.hasAttribute('data-navigation-next') ? wrapper.querySelector(swiperDiv.dataset.navigationNext) : swiperDiv.querySelector('.swiper-next'),
          prevEl: swiperDiv.hasAttribute('data-navigation-prev') ? wrapper.querySelector(swiperDiv.dataset.navigationPrev) : swiperDiv.querySelector('.swiper-prev'),
        },
        pagination: {
          el: swiperDiv.querySelector('.swiper-pagination'),
          type: swiperDiv.hasAttribute('data-pagination-type') ? swiperDiv.dataset.paginationType : undefined,
          dynamicBullets: swiperDiv.querySelectorAll('.swiper-slide').length > 4
                          && swiperDiv.hasAttribute('data-pagination-type')
                          && swiperDiv.dataset.paginationType === 'bullets',
        },
        speed: 400,
        effect: swiperDiv.hasAttribute('data-effect') ? swiperDiv.dataset.effect : 'slide',
        mousewheel: {
          enabled: true,
          forceToAxis: true
        },
        // no swiping
        allowTouchMove: swiperDiv.hasAttribute('data-no-swiping') ? swiperDiv.dataset.noSwiping === 'true' : true,
        disabledClass: 'swiper-button-disabled',
        autoplay: swiperDiv.hasAttribute('data-autoplay') ? {
          delay: swiperDiv.dataset.autoplay
        } : false,
        slidesPerView: swiperDiv.hasAttribute('data-per-view') ?  swiperDiv.dataset.perView : 1.3,
        spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
        centerInsufficientSlides: swiperDiv.hasAttribute('data-centered-insufficient') ? swiperDiv.dataset.centeredInsufficient === 'true' : true,
        centeredSlides: swiperDiv.hasAttribute('data-centered') ? swiperDiv.dataset.centered === 'true' : false,
        centeredSlidesBounds: swiperDiv.hasAttribute('data-centered-bounds') ? swiperDiv.dataset.centeredBounds === 'true' : false,
        loop: swiperDiv.hasAttribute('data-loop') ? swiperDiv.dataset.loop === 'true' : false,
        breakpoints: {
          480: {
            slidesPerView: swiperDiv.hasAttribute('data-per-view-xs') ?  swiperDiv.dataset.perViewXs : 3.3,
            spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
          },
          640: {
            slidesPerView: swiperDiv.hasAttribute('data-per-view-sm') ?  swiperDiv.dataset.perViewSm : 3.3,
            spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
          },
          1024: {
            slidesPerView: swiperDiv.hasAttribute('data-per-view-lg') ?  swiperDiv.dataset.perViewLg : 4.3,
            spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
          },
          1280: {
            slidesPerView: swiperDiv.hasAttribute('data-per-view-xl') ?  swiperDiv.dataset.perViewXl : 4.3,
            spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
          },
          1440: {
            slidesPerView: swiperDiv.hasAttribute('data-per-view-wide-xl') ?  swiperDiv.dataset.perViewWideXl : 4.3,
            spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
          },
          2000: {
            slidesPerView: swiperDiv.hasAttribute('data-per-view-ultrawide-xl') ?  swiperDiv.dataset.perViewUltrawideXl : 10.6,
            spaceBetween: swiperDiv.hasAttribute('data-space-between') ? Number(swiperDiv.dataset.spaceBetween) : 20,
          },
        },
        on: {
          init: function () {
            window.setTimeout(() => {
              if(swiperDiv.classList.contains('cls-fix')){
                swiperDiv.classList.remove('cls-fix');

                swiperDiv.swiper.update();
              }

              //remove hidden class from slides (to prevent CLS)
              const hiddenSlides = swiperDiv.querySelectorAll('.slide-img.opacity-0');
              if(hiddenSlides){
                hiddenSlides.forEach(slide => slide.classList.remove('opacity-0'));
              }
            }, 10);

            //workaround for search host experience gallery pictures
            //when an experience mini gallery picture is clicked, make swiper slide to the experience clicked
            handleExperienceGalleryItemClicked(swiperDiv.parentElement);
          },
        },
      });

      // call any callbacks passed in parameters if there are any
      if (options.hasOwnProperty('callbacks')) {
        Object.keys(options.callbacks).forEach(event => {
          swiper.on(event, options.callbacks[event]);
        });
      }

      // start autoplay on mouseenter and stop autoplay on mouseleave
      if(swiperDiv.hasAttribute('data-autoplay-on-hover') || swiperDiv.closest('[data-autoplay-on-hover]')){
        swiperDiv.swiper.autoplay.stop();
        let wrapper = swiperDiv.closest('[data-autoplay-on-hover]') || swiperDiv.querySelector('.swiper-wrapper');
        wrapper.addEventListener('mouseenter', () => {
          swiperDiv.swiper.autoplay.start();
        });
        wrapper.addEventListener('mouseleave', () => {
          swiperDiv.swiper.autoplay.stop();
        });
      }

      //handle any videos on a swiper > play/pause when transitioning
      if(swiperDiv.hasAttribute('data-has-videos')){
        swiperDiv.swiper.on('transitionStart', function () {
          const videos = swiperDiv.querySelectorAll('video');
          videos.forEach(video => {
            video.pause();
          });
        });

        swiperDiv.swiper.on('transitionEnd', function () {
          var activeIndex = this.activeIndex;
          var activeSlide = swiperDiv.getElementsByClassName('swiper-slide')[activeIndex];
          var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
          activeSlideVideo.play();
        });
      }
    });
  }
}

export { initSwiper }
