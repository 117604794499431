const initScroll = () => {
  const _nav = document.querySelector('#navbar-main');
  const logo = document.getElementById('winalist');
  // const _searchBarNav = document.querySelector(".navbar .searchBarNav");

  const getScrollPosition = () => {
    return ((document.documentElement && document.documentElement.scrollTop) || window.pageYOffset || self.pageYOffset || document.body.scrollTop);
  }

  const headerTransparent = () => {
    const scrolling = getScrollPosition();
    if (scrolling < 100) {
      _nav.classList.remove('scrolled');
      if(logo) logo.classList.remove('trigger');
    } else if (!_nav.classList.contains('scrolled')) {
      _nav.classList.add('scrolled');
      if(logo) setTimeout(function(){logo.classList.add('trigger');}, 50);
    }
  }

  if (_nav) {
    if (_nav.dataset.scroll === 'true') {
      window.addEventListener('scroll', function() {
        headerTransparent();
      }, {
        capture: true,
        passive: true
      });

      headerTransparent();
    } else {
      _nav.classList.add('scrolled');
    }

    if(logo) setTimeout(function(){logo.classList.add('trigger');}, 50);
  }
};

export {initScroll};
