class InputNumber {
  constructor(wrapper) {
    if(wrapper) {
      this.input = wrapper.querySelector('input');
      this.plusBtn = wrapper.querySelector('button.plus');
      this.minusBtn = wrapper.querySelector('button.minus');
      this.containerValue = wrapper.querySelector('.value-displayed');
      this.inputNumbersGroup = wrapper.closest('[data-input-number-max]');
      if(this.inputNumbersGroup){
        this.allInputs = this.inputNumbersGroup.querySelectorAll('input');
        this.allPlusBtns = this.inputNumbersGroup.querySelectorAll('button.plus');
      }

      this.containerValue.innerHTML = this.input.value;

      const _this = this;
      const events = ['change', 'updateContainerValue'];
      events.forEach(event => {
        this.input.addEventListener(event, function(event) {
          _this.containerValue.innerHTML = event.target.value;
          _this.checkDisableState();
        });
      });

      if(this.plusBtn && !this.plusBtn.classList.contains('prevent-plus')){
        this.plusBtn.addEventListener('click', () => {this.plus()});
      }

      if(this.minusBtn && !this.minusBtn.classList.contains('prevent-minus')){
        this.minusBtn.addEventListener('click', () => {this.minus()});
      }

      this.common();
      this.checkDisableState();
    }
  }

  common() {
    this.step = this.input.step ? parseFloat(this.input.step) : 1;
    this.value = this.input.value ? parseFloat(this.input.value) : false;
    this.min = this.input.min ? parseFloat(this.input.min) : false;
    this.max = this.input.max ? parseFloat(this.input.max) : false;
  }

  plus() {
    this.common();
    // check if we can
    if(!this.max || this.max >= (this.value + this.step)) {
      this.input.value = (this.value + this.step).toString();
      this.input.dispatchEvent(new Event('change'));
      this.containerValue.innerHTML = this.input.value;
      this.checkDisableState();
    }
  }

  minus() {
    this.common();
    // check if we can
    if(!this.min || this.min <= (this.value - this.step)) {
      this.input.value = (this.value - this.step).toString();
      this.input.dispatchEvent(new Event('change'));
      this.containerValue.innerHTML = this.input.value;
      this.checkDisableState();
    }
  }

  checkDisableState() {
    if(this.inputNumbersGroup && this.allPlusBtns){
      let totalValue = 0;
      this.allInputs.forEach(input => {
        totalValue += parseFloat(input.value);
      });

      this.allPlusBtns.forEach(button => {
        button.disabled = parseFloat(totalValue) >= parseFloat(this.inputNumbersGroup.dataset.inputNumberMax);
      });

      if(this.min !== false) {
        this.minusBtn.disabled = parseFloat(this.input.value) <= this.min;
      }
    }
    else{
      if(this.max !== false) {
        this.plusBtn.disabled = parseFloat(this.input.value) >= this.max;
      }

      if(this.min !== false) {
        this.minusBtn.disabled = parseFloat(this.input.value) <= this.min;
      }
    }
  }
}

export default InputNumber;
