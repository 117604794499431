// ? How to use: import { initMelchiorInput } from "./modules/common/init-melchior-input";
// initMelchiorInput();
// ? Description: Concerns melchior type inputs (input + label) so that the label moves above the input when you click on it

const TIME_TO_REMOVE_INVALID_CLASS = 3000;

/**
 * Initializes the Melchior input functionality.
 *
 * @return {undefined} This function does not return a value.
 */
const initMelchiorInput = (wrapper = document) => {
    const melchiors = wrapper.querySelectorAll(".melchior-input:not(.binded)");
    melchiors.forEach((melchior) => {
        const input = melchior.querySelector("input");
        const select = melchior.querySelector("select");
        const textarea = melchior.matches('textarea') ? melchior : null;
        if (input) {
            // if input has content, we add the class has-content
            if (input.value !== "") {
                melchior.classList.add("has-content");
            }

            // we add the class has-content when the input is focused or when the input has content
            input.addEventListener("focus", (e) => handleInputChange(e, melchior, true));
            input.addEventListener("blur", (e) => handleInputChange(e, melchior));
            input.addEventListener("change", (e) => handleInputChange(e, melchior));
            // if another div than input or label is clicked, we focus the input
            melchior.addEventListener("click", (e) => handleInputClick(e, input));
        }
        else if (select){
            melchior.classList.add('label-on-top');
            // First we check if the option is disabled
            const optionSelected = select.selectedOptions[select.selectedIndex];
            if(optionSelected){
                if (optionSelected.disabled) {
                    select.classList.add("text-gray-500");
                } else {
                    select.classList.remove("text-gray-500");
                }
            }
            select.addEventListener("change", (e) => handleSelectChange(e));
        } else if (textarea) {
            // if textarea has content, we add the class has-content
            if (textarea.value !== "") {
                melchior.classList.add("has-content");
            }

            // we add the class has-content
            textarea.addEventListener("focus", (e) => handleInputChange(e, melchior, true));
            textarea.addEventListener("blur", (e) => handleInputChange(e, melchior));
            textarea.addEventListener("change", (e) => handleInputChange(e, melchior));
            // if another div than input or label is clicked, we focus the input
            melchior.addEventListener("click", (e) => handleInputClick(e, textarea));
        }

        melchior.classList.add('binded');

        initInvalidInput(melchior);
    });

    /**
     * Handles the change event of the input element.
     *
     * @param {Event} e - The event object.
     * @param {HTMLElement} melchior - The melchior element.
     */
    function handleInputChange(e, melchior, focus = false) {
        const input = e.target;
        melchior.classList.toggle("has-content", input.value !== "" || focus);
        melchior.classList.toggle("focus-input", focus);
    }

    /**
     * Handles the click event on an input element.
     *
     * @param {Event} e - The click event.
     * @param {HTMLInputElement} input - The input element.
     */
    function handleInputClick(e, input) {
        const element = e.target;
        if (element.tagName !== "INPUT" && element.tagName !== "LABEL") {
            input.focus();
        }
    }

    /**
     * Handles the change event of the select element.
     *
     * @param {Event} e - The event object representing the select change event.
     */
    function handleSelectChange(e) {
        const select = e.target;
        const optionSelected = select.selectedOptions[0];
        if (optionSelected.disabled) {
            select.classList.add("text-gray-500");
        } else {
            select.classList.remove("text-gray-500");
        }
    }

    // If melchior input is required and invalid 
    // -> we add the class invalid to the melchior element
    function initInvalidInput(melchior) {
        // Special case for textarea because melchior-input class is on the textarea element
        const element = melchior.matches('textarea') 
                            ? melchior 
                            : melchior.querySelector('input, select');

        let timeoutId;

        if (element) {
            element.addEventListener('invalid', () => {
                melchior.classList.add('invalid');
    
                // Clear the previous timeout
                clearTimeout(timeoutId);
    
                // Set a new timeout
                timeoutId = setTimeout(() => {
                    melchior.classList.remove('invalid');
                }, TIME_TO_REMOVE_INVALID_CLASS);
            });
        }
    }
};

/**
 * Adds style on focus and blur of input.
 *
 * @param {HTMLElement} stripeObject - The input element to listen for focus and blur events.
 * @param {HTMLElement} stripeElement - The input element to apply the style.
 * @returns {void}
 */
const initStyleFocusBlur = (stripeObject, stripeElement) => {
    const style = [
        "border-me-deep-green",
        "has-content",
    ];

    const melchiorElement = stripeElement.closest('.melchior-input');
    if (melchiorElement) {
        stripeObject.addEventListener('focus', () => {
            melchiorElement.classList.add(...style);
        });

        stripeObject.addEventListener('blur', () => {
            melchiorElement.classList.remove(...style);
        });
    }
}

export { initMelchiorInput, initStyleFocusBlur };
