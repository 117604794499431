// On click on language or currency button => change the tab

// ? How to use it:
// ? Add the class "btn-language" or "btn-currency" to the button
const bindLanguageAndCurrencyBtn = () => {
    let modal = document.querySelector('.modal#chooseLanguageCurrency');
    if (modal){
      let changeLanguages = document.querySelectorAll('.btn-language:not(.binded-language-currency)');
      let changeCurrencies = document.querySelectorAll('.btn-currency:not(.binded-language-currency)');

      changeLanguages.forEach((changeLanguage) => {
          changeLanguage.addEventListener('click', () => {
              modal.classList.add('active');
              modal.querySelector('#tab-language')?.click();
          });
          changeLanguage.classList.add('binded-language-currency');
      })

      changeCurrencies.forEach((changeCurrency) => {
          changeCurrency.addEventListener('click', () => {
              modal.classList.add('active');
              modal.querySelector('#tab-currency')?.click();
          });
          changeCurrency.classList.add('binded-language-currency');
      });
    }
}

export { bindLanguageAndCurrencyBtn }
