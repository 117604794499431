const initScrollSearchBar = () => {
  window.addEventListener('scroll', function(){
    let onMobile = window.matchMedia("(max-width: 767px)").matches;
    if(onMobile){
      var searchBar = document.getElementById('search-bar-mobile');
      var searchBarNav = document.getElementById('triggerSearch');
    } else {
      var searchBar = document.getElementById('search-bar');
      var searchBarNav = document.getElementById('aa-input-container');
    }

    if(searchBar && searchBarNav){
      let position = searchBar.getBoundingClientRect();

      let className = onMobile ? 'invisible' : 'hidden';
      if(!(position.top >= 0 && position.bottom <= window.innerHeight)) {
        searchBarNav.classList.remove(className);
      } else {
        searchBarNav.classList.add(className);
      }
    }
  });

  window.dispatchEvent(new Event('scroll'));
}

export { initScrollSearchBar };
