class Message {
  static success(message, removeAfter = 4000) {
    const messageDiv = this.createMessageElement('success', message);
    messageDiv.addEventListener('click', function() {
      this.remove();
    });
    setTimeout(function() {
      messageDiv.remove();
    }, removeAfter);
    document.body.appendChild(messageDiv);
  }

  static error(message) {
    const messageDiv = this.createMessageElement('error', message);
    messageDiv.addEventListener('click', function() {
      this.remove();
    });
    setTimeout(function() {
      messageDiv.remove();
    }, 4000);
    document.body.appendChild(messageDiv);
  }

  static createMessageElement(type, message) {
    const div = document.createElement('div');
    // const btn = document.createElement('button');
    // const span = document.createElement('span');
    // span.dataset.ariaHidden = "true";
    // span.innerHTML = '&times;';

    // btn.classList.add('close');
    // btn.dataset.ariaLabel = 'Close';
    // btn.appendChild(span);

    div.classList.add('message', type);
    div.innerHTML = message;

    // div.appendChild(btn);
    return div;
  }
}

export default Message;
