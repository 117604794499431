import { ajax, deleteCookie } from "../utils";
import Modal from './Modal';

class Wish {
  constructor() {
    this.buttonLike;
  }

  static bindWishButtons(wrapper = document) {
    const btnWishlist = wrapper.querySelectorAll('button[data-wishlist]');
    if (btnWishlist.length <= 0) return;

    btnWishlist.forEach((button) => {
      this.titleOfButtonLike(button);
      if (button.hasAttribute('data-delete') || button.hasAttribute('data-wishlist-active')) {
        button.classList.add('btn-wishlist-active');
      }

      // if not connected and item is in cookie => editLikeButton
      if (button.hasAttribute('data-not-connected')) {
        let wish = {
          id: parseInt(button.dataset.modelId),
          model: button.dataset.model,
        }
        if (this.isItemInCookie(wish)) {
          this.editLikeButton(button);
        }
      }

      button.addEventListener('click', () => {
        this.buttonLike = button;

        if (!this.buttonLike.hasAttribute('data-not-connected')) {
          // user connected
          this.onClickButtonConnected();
        } else {
          // user not connected
          this.onClickButtonNotConnected();
        }
      });
    });
  }

  static onClickButtonConnected() {
    // if user has already added this item to his wishlist
    if (this.buttonLike.hasAttribute('data-delete')) {
      // add wish in this wishlist
      this.handleWish(this.buttonLike.dataset.model, this.buttonLike.dataset.modelId, null);
    }
    else {
      this.buttonLike.setAttribute('disabled', '')

      // if modal exists, remove it
      if (document.querySelector('#wishlistModal')) document.querySelector('#wishlistModal').remove();

      // add loading modal
      this.modalLoading();

      ajax(this.buttonLike.dataset.urlContentModal,
      (data) => {
        document.querySelector('#loadingModal').remove();
        document.body.insertAdjacentHTML('beforeend', data);

        Modal.bindAllModals();

        this.initCreateWishlist();
        this.initAddToWishlist();

        this.buttonLike.removeAttribute('disabled');
      },
      'GET',
      null,
      {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      },
      true,
      false,
      );
    }
  }

  static initCreateWishlist() {
    let buttonCreateWishlist = document.querySelector('#createWishlist');
    if (!buttonCreateWishlist) return;

    buttonCreateWishlist.addEventListener('click', () => {
      // remove child into modal body
      document.querySelector('#wishlistModal').querySelector('#contentModal').innerHTML = '';

      let content = `
        <h1 class="pb-6 border-b">${buttonCreateWishlist.dataset.title}</h1>
        <div class="py-6" id="modalBody">
          <div id="newWishlist" class="flex flex-col justify-center text-center w-full md:w-2/3 mx-auto">
            <input type="text" name="wishlist" class="form-control w-full"
                    placeholder="${buttonCreateWishlist.dataset.placeholder}" maxlength="50">
            <span id="invalid-input" class="hidden">${buttonCreateWishlist.dataset.invalidInput}</span>
            <button type="button" id="createWishlist" class="btn btn-outline-red w-full mt-3"
                    data-url="${buttonCreateWishlist.dataset.url}">
                ${buttonCreateWishlist.dataset.textButton}
            </button>
          </div>
        </div>
      `;
      document.querySelector('#wishlistModal').querySelector('#contentModal').insertAdjacentHTML('beforeend', content);

      this.createWishlist();
    });
  }

  static createWishlist() {
    const createWishlist = document.querySelector('button[id=createWishlist]');
    if (!createWishlist) return;

    createWishlist.addEventListener('click', () => {
      let input = document.querySelector('input[name="wishlist"]');

      if (!input || input.value.length <= 0) {
        let invalidInput = document.querySelector('#invalid-input');
        invalidInput.classList.remove('hidden');

        setTimeout(() => {
          invalidInput.classList.add('hidden');
        }, 2000);

        return;
      }

      document.querySelector('#wishlistModal').querySelector('.modal-close').click();
      document.querySelector('#invalid-input').classList.add('hidden');

      let formData = new FormData();
      formData.append('name', input.value);

      // request ajax to add wishlist in database
      ajax(createWishlist.dataset.url,
        (data) => {
          if (data.success) {
            this.handleWish(this.buttonLike.dataset.model, this.buttonLike.dataset.modelId, data.wishlistId);
          }
        },
        'POST',
        formData,
        {
          "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
        },
        true,
        true,
      );
    });
  }

  static initAddToWishlist() {
    const wishlists = document.querySelectorAll('#addToWishlist');
    if (wishlists.length <= 0) return;

    wishlists.forEach((button) => {
      button.addEventListener('click', () => {
        // add wish in this wishlist
        this.handleWish(this.buttonLike.dataset.model, this.buttonLike.dataset.modelId, button.dataset.wishlistId);
      });
    });
  }

  static handleWish(model, modelId, wishlistId) {
    if (document.querySelector('#wishlistModal')) document.querySelector('#wishlistModal').querySelector('.modal-close').click();

    this.updateAllSameWishButtons(this.buttonLike);

    let formData = new FormData();
    formData.append('model', model);
    formData.append('modelId', modelId);
    formData.append('wishlistId', wishlistId);

    if (this.buttonLike.hasAttribute('data-delete')) {
      // add wish in this wishlist
      this.buttonLike.classList.add('animate');
    } else {
      this.buttonLike.classList.remove('animate');
    }

    ajax(this.buttonLike.dataset.url,
      (data) => {
        if (data.success) {
          this.refreshWishesInDatasetBody();
        } else {
          this.updateAllSameWishButtons(this.buttonLike);
        }
      },
      'POST',
      formData,
      {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      },
      true,
      true,
    );
  }

  static editLikeButton(button) {
    if (!button) return;
    let icon = button.querySelector('i');
    if (!icon) return;

    if (icon.classList.contains('icon-heart-filled')) {
      if (!button.hasAttribute('data-wishlist-active')) {
        button.classList.remove('btn-wishlist-active');
      }

      icon.classList.remove('icon-heart-filled');
      icon.classList.add('icon-heart');
      button.removeAttribute('data-delete');
    } else {
      if (!button.classList.contains('btn-wishlist-active')) {
        button.classList.add('btn-wishlist-active');
      }

      icon.classList.remove('icon-heart');
      icon.classList.add('icon-heart-filled');
      button.setAttribute('data-delete', '');
    }
    this.titleOfButtonLike(button);
  }

  static updateAllSameWishButtons(button) {
    if (!button) return;
    let modelId = button.dataset.modelId;
    let model = button.dataset.model;

    let sameButtons = document.querySelectorAll(`button[data-model="${model}"][data-model-id="${modelId}"]`);

    sameButtons.forEach((button) => {
      this.editLikeButton(button);
    });
  }

  static titleOfButtonLike(button) {
    // set title for button
    let icon = button.querySelector('i');

    if (!icon || !button.dataset.textAdd || !button.dataset.textRemove) return;

    if (icon.classList.contains('icon-heart-filled')) {
      button.title = button.dataset.textRemove;
    } else {
      button.title = button.dataset.textAdd;
    }
  }

  static modalLoading() {
    let modalLoading = `
        <div id="loadingModal" class="modal opacity-0 pointer-events-none p-0 active">
            <div class="modal-overlay"></div>
            <div class="modal-container md:max-w-lg lg:max-w-2xl">
                <div class="modal-content py-4 text-left px-6 flex flex-col bg-dashboard">
                    <div class="set-min-height loading-spinner"></div>
                </div>
            </div>
        </div>
    `;

    document.body.insertAdjacentHTML('beforeend', modalLoading);
  }

  // for filter in experience and host
  static refreshWishesInDatasetBody() {
    ajax("/wish/refresh",
      (data) => {
        if (data.success) {
          document.body.dataset.wishes = JSON.stringify(data.wishes);

          // compute number of wishes
          let count = 0;
          for (let model in data.wishes) {
            count += data.wishes[model].length;
          }

          let nbWishesSpans = document.querySelectorAll('.nbWishes');
          if (nbWishesSpans.length <= 0) return;

          nbWishesSpans.forEach((nbWishesSpan) => {
            // refresh number of wishes in navbar
            nbWishesSpan.innerHTML = count;
            if (nbWishesSpan.innerHTML === "0") nbWishesSpan.classList.add('hidden');
            else nbWishesSpan.classList.remove('hidden');
          });
        }
      },
      'GET',
      null,
      {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      },
      true,
      true,
    );
  }

  // -------------------------------- User not connected ------------------------------ //

  static onClickButtonNotConnected() {
    if (!["Experience", "Host"].includes(this.buttonLike.dataset.model)) return;

    this.updateAllSameWishButtons(this.buttonLike);

    if (this.buttonLike.hasAttribute('data-delete')) {
      // add wish in this wishlist
      this.buttonLike.classList.add('animate');
    } else {
      this.buttonLike.classList.remove('animate');
    }

    let wish = {
      id: parseInt(this.buttonLike.dataset.modelId),
      model: this.buttonLike.dataset.model,
    }

    this.toggleItemInCookie(wish);
  }

  // toggle item in cookie
  static toggleItemInCookie(item) {
    const defaultCookieValue = {experiences: [], hosts: []};
    const cookieName = 'wishlist';
    const cookieValue = this.getCookieValue(cookieName);
    const wishes = JSON.parse(decodeURIComponent(cookieValue)) ?? defaultCookieValue;

    let nbWishesSpans = document.querySelectorAll('.nbWishes');
    if (!nbWishesSpans) return;

    if (["Experience", "Host"].includes(item.model)) {
      const modelKey = item.model.toLowerCase() + 's';
      const isItemInCookie = this.isItemInCookie(item);

      if (isItemInCookie) {
        wishes[modelKey] = wishes[modelKey].filter(obj => obj.id !== item.id);
      } else {
        wishes[modelKey].push({id: item.id});
      }

      nbWishesSpans.forEach((nbWishesSpan) => {
        const newCount = isItemInCookie
                          ? parseInt(nbWishesSpan.innerHTML) - 1
                          : parseInt(nbWishesSpan.innerHTML) + 1;

        nbWishesSpan.innerHTML = newCount;

        if (newCount === 0) {
          nbWishesSpan.classList.add('hidden');
        } else {
          nbWishesSpan.classList.remove('hidden');
        }
      });
    }

    if(wishes.experiences.length == 0 && wishes.hosts.length == 0){
      deleteCookie(cookieName);
    }
    else{
      this.setCookieValue(wishes);
    }
  }

  // know if an item is in the cookie
  static isItemInCookie(item) {
    const cookieValue = this.getCookieValue("wishlist");
    if (cookieValue) {
      const wishes = JSON.parse(decodeURIComponent(cookieValue));

      if (["Experience", "Host"].includes(item.model))
        return wishes[item.model.toLowerCase() + 's'].some(obj => obj.id === item.id);
    }
    return false;
  }

  // get cookie value
  static getCookieValue(cookieName) {
    let cookie = document.cookie.split('; ').find(row => row.startsWith(cookieName));
    return cookie?.split('=')[1] ?? null;
  }

  // set cookie value
  static setCookieValue(cookieValue) {
    const yearExpiration = 1; // max 13 months for cookie
    let date = new Date();
    date.setTime(date.getTime() + (yearExpiration * 365 * 24 * 60 * 60 * 1000)); // day, hour, minute, second, millisecond

    document.cookie = `wishlist=${encodeURIComponent(JSON.stringify(cookieValue))};expires=${date.toUTCString()};path=/;`;
    document.cookie = `prevent_varnish=${JSON.stringify(true)};expires=${date.toUTCString()};path=/`;
  }
}

export default Wish;
