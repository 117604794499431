import InputNumber from "../frontv2/InputNumber";

/**
 * Initializes input number elements.
 *
 * @return {undefined} No return value.
 */
const initInputNumber = () => {
  const allInputNumber = document.querySelectorAll('div.input-number:not(.binded)');
  if (allInputNumber.length > 0) {
    allInputNumber.forEach((wrapperInputNumber) => {
      new InputNumber(wrapperInputNumber);
      wrapperInputNumber.classList.add('binded');
    });
  }
}

export {initInputNumber}
