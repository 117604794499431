/**
 * Initializes the cards effect on home top section.
 *
 * @return {undefined} No return value.
 */
const initCardsEffect = () => {
  const wrapper = document.querySelector('.cards-effect-wrapper');
  if(wrapper){
    const cards = wrapper.querySelectorAll('.effect-card');
    const arrows = wrapper.querySelectorAll('.cards-effect-arrow');
    let motion = null;
    if(cards){
      //set autoplay every 7000ms
      motion = setAutomaticMotion(cards);

      //animate on cards click
      cards.forEach(card => {
        card.addEventListener('click', () => {
          animate(cards);

          //reset the motion
          clearInterval(motion);
          motion = setAutomaticMotion(cards);
        });
      });

      if(arrows){
        arrows.forEach(arrow => {
          arrow.addEventListener('click', () => {
            animate(cards, arrow.classList.contains('cards-effect-arrow-right') ? 'right' : 'left');

            //reset the motion
            clearInterval(motion);
            motion = setAutomaticMotion(cards);
          });
        });
      }
    }
  }
}

/**
 * Animate the cards on click by incrementing the position class on each card.
 * If the position class is 3, put it back to 1.
 *
 * @param {Array} cards - The array of cards to animate.
 */
const animate = (cards, direction = 'right') => {
  //on click of a card, increment the position class on each
  //if it's 3, put back to 1
  cards.forEach(innerCard => {
    if(innerCard.classList.contains('position-1')){
      innerCard.classList.remove('position-1');
      direction === 'right' ? innerCard.classList.add('position-2') : innerCard.classList.add('position-3');
    }
    else if(innerCard.classList.contains('position-2')){
      innerCard.classList.remove('position-2');
      direction === 'right' ? innerCard.classList.add('position-3') : innerCard.classList.add('position-1');
    }
    else if(innerCard.classList.contains('position-3')){
      innerCard.classList.remove('position-3');
      direction === 'right' ? innerCard.classList.add('position-1') : innerCard.classList.add('position-2');
    }
  });
}

/**
 * Sets automatic motion for the given cards.
 *
 * @param {array} cards - The array of cards to animate.
 * @return {number} The ID of the interval set for automatic motion.
 */
const setAutomaticMotion = (cards) => {
  return window.setInterval(() => {
    animate(cards);
  }, 7000);
}

export { initCardsEffect }
