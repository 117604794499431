import {ajax} from "../utils";

class AuthenticationForm {
  constructor() {
    this.wrappers = document.querySelectorAll('#signup-form, #login-form, #reset-password-form');
    if(this.wrappers) {
      this.wrappers.forEach(wrapper => {
        //signup form
        if(wrapper.id === 'signup-form'){
          this.passwordCheckerNotOk = true;
          this.emailAlreadyExists = false;

          //init the password checker for account creation
          this.initPasswordChecker();

          this.signUpEmail = wrapper.querySelector('#signup-mail');
          this.signUpEmailWrapper = this.signUpEmail.closest('div');
          this.signUpEmailExistsMessage = wrapper.querySelector('.email-exists-message');
          this.signUpSubmitButton = wrapper.querySelector("button[type=submit]");
          if(this.signUpEmail && this.signUpEmailWrapper && this.signUpEmailExistsMessage && this.signUpSubmitButton){
            this.signUpEmail.addEventListener('blur', () => {
             this.checkIfSignUpEmailExists();
            });

            //remove error when user changes the email input
            this.removeSignUpEmailError();
          }

          this.signUpForm = wrapper;
          this.signUpPassword = wrapper.querySelector('#signup-password');
          this.signUpRepeatPassword = wrapper.querySelector('#signup-repeat-password');
          this.signUpFailedPasswordMessage = wrapper.querySelector('.failed-repeat-password');
          this.signUpPasswordWrapper = this.signUpPassword.closest('div');
          this.signUpRepeatPasswordWrapper = this.signUpRepeatPassword.closest('div');
          this.signUpPasswordFields = [this.signUpPassword, this.signUpRepeatPassword];
          if(this.signUpForm && this.signUpPassword && this.signUpRepeatPassword
            && this.signUpFailedPasswordMessage && this.signUpPasswordWrapper && this.signUpRepeatPasswordWrapper){
            this.signUpForm.addEventListener('submit', (e) => {
              //check if password = repeat password
              let match = this.checkIfPasswordsMatch();
              if(!match){
                e.preventDefault();
                e.stopPropagation();
              }
              else{
                this.signUpForm.classList.add('checks-validated');
              }
            });

            //remove failed repeat pw error message if user changes the password or repeat password fields
            this.removeFailedRepeatedPasswordError();
          }

          this.loginHere = wrapper.querySelector('.login-here');
          if(this.loginHere){
            //copy the email input value from signup form to login form to ease process
            this.loginHere.addEventListener('click', () => {
              this.copyEmailFromSignUpToLoginForm();
            });
          }
        }

        //reset password form (first connection or password forgotten)
        else if(wrapper.id === 'reset-password-form'){
          this.initPasswordChecker(false);
        }

        //login form
        else{
          this.loginEmail = wrapper.querySelector('#login-mail');
          this.loginEmailWrapper = this.loginEmail.closest('div');
          // this.loginEmailNotExistsMessage = wrapper.querySelector('.email-not-exists-message');
          this.loginSubmitButton = wrapper.querySelector("button[type=submit]");

          this.loginPassword = wrapper.querySelector('#login-password');
          this.loginPasswordWrapper = this.loginPassword.closest('div');
          this.loginErrorMessage = wrapper.querySelector('.user-not-exists');
          this.loginForm = wrapper;

          this.loginFields = [this.loginEmail, this.loginPassword];

          //try to login the user through ajax and redirect or display error message if failed
          if(this.loginForm && this.loginEmail && this.loginPassword && this.loginErrorMessage && this.loginPasswordWrapper){
            this.loginForm.addEventListener('submit', (evt) => {
              this.loginOrError(evt);
            });

            //remove error if failed login when user changes email or password fields
            this.removeFailedLoginError();
          }

          //check if login email actually exists on email field blur event
          // if(this.loginEmail && this.loginEmailWrapper && this.loginEmailNotExistsMessage && this.loginSubmitButton){
          if(this.loginEmail && this.loginEmailWrapper && this.loginSubmitButton){
            //remove error when user changes the email input
            this.removeLoginEmailError();
          }
        }
      });
    }

    this.initMagicLink();
  }

  initPasswordChecker(checkEmail = true){
    const passwordChecks = document.querySelectorAll('[data-password-checker]');
      if(passwordChecks){
        passwordChecks.forEach((passwordCheck) => {
          const password = document.getElementById(passwordCheck.dataset.passwordInput);
          if(password){
            const button = document.getElementById(passwordCheck.dataset.button);

            let numberValid = false;
            let lengthValid = false;
            let symbolValid = false;

            const numberWrapper = passwordCheck.querySelector('[data-check-numbers]');
            const numberIcon = numberWrapper.querySelector("i");

            const lengthWrapper = passwordCheck.querySelector('[data-check-characters]');
            const lengthIcon = lengthWrapper.querySelector("i");

            const symbolWrapper = passwordCheck.querySelector('[data-check-symbols]');
            const symbolIcon = symbolWrapper.querySelector("i");

            ['keyup','change'].forEach( evt =>
              password.addEventListener(evt, () => {
                //Validate length
                if(password.value.length >= 8) {
                  lengthWrapper.classList.remove("invalid");
                  lengthIcon.classList.remove("icon-cross");
                  lengthIcon.classList.add("icon-simple-check");
                  lengthValid = true;
                }
                else {
                  lengthWrapper.classList.add("invalid");
                  lengthIcon.classList.add("icon-cross");
                  lengthIcon.classList.remove("icon-simple-check");
                  lengthValid = false;
                }

                //Validate at least one number or one capital letter
                let numbers = /[0-9]/g;
                let oneCapitalLetter = /(.*[A-Z].*)/g;
                if(password.value.match(numbers) || password.value.match(oneCapitalLetter)) {
                  numberWrapper.classList.remove("invalid");
                  numberIcon.classList.add("icon-simple-check");
                  numberIcon.classList.remove("icon-cross");
                  numberValid = true;
                }
                else {
                  numberWrapper.classList.add("invalid");
                  numberIcon.classList.remove("icon-simple-check");
                  numberIcon.classList.add("icon-cross");
                  numberValid = false;
                }

                //Validate at least one symbol
                let symbols = /[-!$%^&@#*()_+|~=`{}\[\]:";'<>?,.\/]/;
                if(password.value.match(symbols)) {
                  symbolWrapper.classList.remove("invalid");
                  symbolIcon.classList.add("icon-simple-check");
                  symbolIcon.classList.remove("icon-cross");
                  symbolValid = true;
                }
                else {
                  symbolWrapper.classList.add("invalid");
                  symbolIcon.classList.remove("icon-simple-check");
                  symbolIcon.classList.add("icon-cross");
                  symbolValid = false;
                }

                if(numberValid && lengthValid && symbolValid){
                  this.passwordCheckerNotOk = false;
                  if(!this.signUpEmailAlreadyExists || !checkEmail){
                    button.disabled = false;
                  }
                }
                else{
                  button.disabled = true;
                  this.passwordCheckerNotOk = true;
                }
              }, false)
            );
          }
        });
      }
  }

  //checks if email which user tries to sign up with already exists
  checkIfSignUpEmailExists(){
    ajax(`/api/users/exists?mail=${this.signUpEmail.value}`, (res) => {
      if(res.data === true) {
        this.signUpEmailExistsMessage.classList.remove('hidden');
        this.signUpSubmitButton.disabled = this.signUpEmailAlreadyExists = true;
        this.signUpEmailWrapper.classList.add('invalid');
      }
    }, 'GET', {}, {}, true, true, false);
  }

  removeSignUpEmailError(){
    ['keyup','change'].forEach( evt => {
      this.signUpEmail.addEventListener(evt, () => {
        if(this.signUpEmailWrapper.classList.contains('invalid')){
          this.signUpEmailExistsMessage.classList.add('hidden');
          this.signUpEmailAlreadyExists = false;
          this.signUpSubmitButton.disabled = this.passwordCheckerNotOk;
          this.signUpEmailWrapper.classList.remove('invalid');
        }
      });
    });
  }

  checkIfPasswordsMatch(){
    if(this.signUpPassword.value !== this.signUpRepeatPassword.value){
      this.signUpFailedPasswordMessage.classList.remove('hidden');
      this.signUpPasswordWrapper.classList.add('invalid');
      this.signUpRepeatPasswordWrapper.classList.add('invalid');
      this.signUpSubmitButton.disabled = true;
    }

    return this.signUpPassword.value === this.signUpRepeatPassword.value;
  }

  removeFailedRepeatedPasswordError(){
    ['keyup','change'].forEach( evt => {
      this.signUpPasswordFields.forEach(input => {
        input.addEventListener(evt, () => {
          if(this.signUpRepeatPasswordWrapper.classList.contains('invalid')){
            this.signUpFailedPasswordMessage.classList.add('hidden');
            this.signUpSubmitButton.disabled = this.passwordCheckerNotOk;
            this.signUpPasswordWrapper.classList.remove('invalid');
            this.signUpRepeatPasswordWrapper.classList.remove('invalid');
          }
        });
      });
    });
  }

  copyEmailFromSignUpToLoginForm(){
    if(this.signUpEmail.value !== ''){
      this.loginEmail.value = this.signUpEmail.value;
    }
  }

  removeLoginEmailError(){
    ['keyup','change'].forEach(event => {
      this.loginFields.forEach(input => {
        input.addEventListener(event, () => {
          if(this.loginEmailWrapper.classList.contains('invalid')){
            // this.loginEmailNotExistsMessage.classList.add('hidden');
            this.loginSubmitButton.disabled = false;
            this.loginEmailWrapper.classList.remove('invalid');
          }
        });
      });
    });
  }

  removeFailedLoginError(){
    ['keyup','change'].forEach(event => {
      this.loginFields.forEach(input => {
        input.addEventListener(event, () => {
          if(this.loginPasswordWrapper.classList.contains('invalid')){
            this.loginErrorMessage.classList.add('hidden');
            this.loginSubmitButton.disabled = false;
            this.loginPasswordWrapper.classList.remove('invalid');
          }
        });
      });
    });
  }

  loginOrError(evt){
    evt.preventDefault();

    let formData = new FormData();
    formData.append('mail', this.loginEmail.value);
    formData.append('password', this.loginPassword.value);
    formData.append('ajax', true);

    ajax(this.loginForm.action, (data) => {
      if(data.success) {
        //set cookie to prevent varnish cache when user is connected
        let date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)); // day, hour, minute, second, millisecond
        document.cookie = `prevent_varnish=${JSON.stringify(true)};expires=${date.toUTCString()};path=/`;

        this.loginSubmitButton.disabled = true;
        window.location.href = data.redirectUrl;
      }
      else{
        this.loginErrorMessage.classList.remove('hidden');
        this.loginPasswordWrapper.classList.add('invalid');
      }

      this.loginSubmitButton.disabled = false;
      this.loginSubmitButton.classList.remove('loading')
    }, 'POST', formData);
  }

  initMagicLink(){
    let magicLinkForm = document.querySelector('#magic-link-form');

    if (!magicLinkForm) return;

    const button = magicLinkForm.querySelector('button');
    const input = magicLinkForm.querySelector('input');
    const successMessage = document.querySelector('#message-magic-link');
    if(input && button && successMessage){
      button.addEventListener('click', () => {

        if (input.value === '' || !this.isEmailValid(input.value)) {
          input.setAttribute('oninvalid', 'this.setCustomValidity("' + input.dataset.oninvalid + '")');
          input.setAttribute('oninput', 'this.setCustomValidity("")');
          input.reportValidity();
          return;
        }

        if (this.isEmailValid(input.value)) {
          let formData = new FormData();
          formData.append('mail', input.value);

          button.disabled = true;
          ajax(magicLinkForm.dataset.url,
            (data) => {
              if (data.success) {
                if(successMessage.classList.contains('hidden')) {
                  successMessage.classList.remove('hidden');
                }

                button.innerHTML = button.dataset.messageSubmit;
                window.setTimeout(() => {
                  button.innerHTML = button.dataset.initialText;
                  button.disabled = false;
                }, 4000);
              }
              else{
                button.disabled = false;
              }
            },
            'POST',
            formData,
            {
              "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
            },
            true,
            true
          );
        }
      });

      input.addEventListener('focus', () => {
        button.innerHTML = button.dataset.initialText;
        button.disabled = false;
        successMessage.classList.add('hidden');
      });
    }


    let btnMoveToMagicLink = document.querySelector('#move-to-magic-link');
    if (btnMoveToMagicLink) {
      btnMoveToMagicLink.addEventListener('click', () => {
        document.querySelector('#tab-fast-login').click();
      });
    }

    let btnMoveToManualLogin = document.querySelector('#move-to-manual-login');
    if (btnMoveToManualLogin) {
      btnMoveToManualLogin.addEventListener('click', () => {
        document.querySelector('#tab-manual-login').click();
      });
    }
  }

  isEmailValid(email) {
    let regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  }
}

export default AuthenticationForm;
