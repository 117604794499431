const initSelects = () => {
  const langSelects = document.querySelectorAll(".select-language");
  if (langSelects.length > 0) {
    langSelects.forEach(function (wrapper) {
      const select = wrapper.querySelector('select');
      if (select.options.length > 0) {
        wrapper.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
        select.addEventListener('change', function () {
          wrapper.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
        });
      }
    });
  }


  const langSwitcher = document.querySelector(".switch-lang");
  if (langSwitcher) {
    const select = langSwitcher.querySelector('select');
    langSwitcher.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
    select.addEventListener('change', function () {
      langSwitcher.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
      if (select.options[select.selectedIndex].dataset.link) {
        window.location.href = select.options[select.selectedIndex].dataset.link;
      }
    });
  }
}

export { initSelects }
