const initMessages = () => {
  const messages = document.querySelectorAll('div.message');
  if (messages.length > 0) {
    messages.forEach(function (message) {
      message.querySelector('button.close').addEventListener('click', function () {
        message.remove();
      });

      message.addEventListener('click', () => {
        message.remove();
      });
    });
  }
}

export { initMessages }
