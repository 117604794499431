const initDrops = (wrapperElt = null) => {
  const wrapper = wrapperElt ? wrapperElt : document;
  const allDropdowns = wrapper.querySelectorAll('.dropdown');
  if (allDropdowns.length > 0) {
    allDropdowns.forEach(function (dropdown) {
      const buttons = dropdown.querySelectorAll('[data-toggle="dropdown"]:not(.dropdown-binded)');
      if (buttons && dropdown.dataset.dropdownType != 'mouseenter') {
        buttons.forEach(function (button) {
          button.addEventListener('click', function () {
            allDropdowns.forEach(function (otherDropdown) {
              if (dropdown !== otherDropdown && otherDropdown.classList.contains('active')) {
                otherDropdown.classList.remove('active');
              }
            });

            dropdown.classList.toggle('active');
          });
          button.classList.add('dropdown-binded');
        });
      }

      if(dropdown.hasAttribute('data-dropdown-type') && dropdown.dataset.dropdownType == 'mouseenter'){
        const dropdownContainer = dropdown.querySelector('.dropdown-container');
        let timeoutMouseleave;

        dropdown.addEventListener('mouseenter', () => {
          dropdown.classList.add('active');
          clearTimeout(timeoutMouseleave);
        });

        if(dropdownContainer){
          dropdownContainer.addEventListener('mouseenter', () => {
            dropdown.classList.add('force-active');
          });
          dropdownContainer.addEventListener('mouseleave', () => {
            dropdown.classList.remove('force-active');
          });
        }

        dropdown.addEventListener('mouseleave', () => {
          timeoutMouseleave = window.setTimeout(() => {
            dropdown.classList.remove('active');
          }, 150)
        });
      }

      const closeElements = dropdown.querySelectorAll('.close-drop:not(.dropdown-close-binded)');
      if(closeElements && dropdown.dataset.dropdownType != 'mouseenter'){
        closeElements.forEach(close => {
          close.addEventListener('click', () => {
            dropdown.classList.remove('active');
          });

          close.classList.add('dropdown-close-binded');
        });
      }
    });
  }


  const allDropups = wrapper.querySelectorAll('.dropup');
  if (allDropups.length > 0) {
    allDropups.forEach(function (dropup) {
      const buttons = dropup.querySelectorAll('button[data-toggle="dropup"]');
      if (buttons) {
        buttons.forEach(function (button) {
          button.addEventListener('click', function () {
            allDropups.forEach(function (otherDropdown) {
              if (dropup !== otherDropdown) {
                otherDropdown.classList.remove('active');
              }
            });
            dropup.classList.toggle('active');
          });
        });
      }
    });
  }

  window.addEventListener('click', function (e) {
    // if click is outside dropdown
    if (!e.target.classList.contains('dropdown') && e.target.closest('.dropdown') === null) {
      allDropdowns.forEach(function (dropdown) {
        if(dropdown.classList.contains('active')){ //check first if it contains the active class, otherwise the remove triggers a mutation (for the mutation observer) for nothing
          dropdown.classList.remove('active');
        }
      })
    }
    // if click is outside dropup
    if (!e.target.classList.contains('dropup') && e.target.closest('.dropup') === null) {
      allDropups.forEach(function (dropup) {
        if(dropup.classList.contains('active')){ //check first if it contains the active class, otherwise the remove triggers a mutation (for the mutation observer) for nothing
          dropup.classList.remove('active');
        }
      })
    }
  });
}

export {initDrops}
