//For superadmin users
//Modal to switch between hosts easily (with a quick by name search available)
class SwitchHost {
  constructor() {
    this.wrapper = document.getElementById("switchModal");
    if(this.wrapper){
      const _this = this;

      this.switch = this.wrapper.querySelector('.switch-host');
      this.hosts = this.wrapper.querySelectorAll('.host');
      this.activeHost = null;
      this.search = this.wrapper.querySelector('#search');

      if(this.hosts){
        this.hosts.forEach(function(host){
          host.addEventListener('click', () => _this.onHostClick(host));
        });
      }

      if(this.search){
        this.search.addEventListener('keyup', () => this.onSearchKeyUp());
      }
    }
  }

  onHostClick(host){
    if(this.activeHost){
      this.activeHost.classList.remove('active');
    }

    host.classList.add('active');
    this.activeHost = host;

    this.switch.href = this.switch.dataset.url.replace(':id', host.dataset.id);
  }

  onSearchKeyUp(){
    let value = this.search.value.toUpperCase();

    this.hosts.forEach(function(host){
      let hostName = host.dataset.name.toUpperCase();

      if(!hostName.includes(value)){
        host.classList.add('hidden');
      } else {
        host.classList.remove('hidden');
      }
    });
  }
}

export default SwitchHost;
