const initGiftCardTopBannerCollapse = () => {
  const giftCardTopBanner = document.querySelector('#use-gift-card-banner');
  //TODO rework partner header
  const navbar = document.querySelector('#nav-bar') ?? document.querySelector('#navbar-main');
  const partnerMobileNavbar = document.querySelector('header.partner-mobile');
  if(giftCardTopBanner && navbar){
    window.addEventListener('scroll', () => {
      navbar.classList.toggle('gift-card-top-banner-collapsed', window.scrollY > 0);
      if(partnerMobileNavbar){
        partnerMobileNavbar.classList.toggle('gift-card-top-banner-collapsed', window.scrollY > 0);
      }

      giftCardTopBanner.classList.toggle('collapsed', window.scrollY > 0);
    });
  }
}

export { initGiftCardTopBannerCollapse };
