const initSeeMoreContent = () => {
  const seeMoreButtons = document.querySelectorAll('.see-more-content');
  if(seeMoreButtons){
    seeMoreButtons.forEach(button => {
      const target = document.getElementById(button.dataset.target);
      if(target){
        const offsetHeight = target.offsetHeight;

        button.addEventListener('click', () => {
          if(button.classList.contains('toggled')){
            target.style.maxHeight = target.dataset.maxHeight + 'px';
            button.textContent = button.dataset.seeMore;
          }
          else {
            target.style.maxHeight = Number(offsetHeight + 30) + 'px';
            button.textContent = button.dataset.seeLess;
          }

          button.classList.toggle('toggled');
        });

        target.style.maxHeight = target.dataset.maxHeight + 'px';

        if(target.scrollHeight <= target.dataset.maxHeight){
          button.classList.add('hidden');
        }
      }
    });
  }
}

export { initSeeMoreContent }
