//when a slide finish transitioning, highlight the experience mini gallery picture
const highlighExperienceGalleryItem = (wrapper, swiper, forceActiveFirstIndex = false) => {
  //remove the current highlighted experience picture
  const activeExperiencePicture = wrapper.querySelector(`.gallery-item.active`);
  activeExperiencePicture?.classList.remove('active');

  let activeIndex = forceActiveFirstIndex ? 0 : swiper.activeIndex;
  //find the experience gallery picture with this index in the popup, then highlight it
  const experiencePictureToActive = wrapper.querySelector(`.gallery-item[data-index='${activeIndex}']`);
  experiencePictureToActive?.classList.add('active');
}

const handleExperienceGalleryItemClicked = (wrapper) => {
  const experienceButtons = wrapper.querySelectorAll('.gallery-item:not(.binded)');
  if(experienceButtons){
    experienceButtons.forEach(button => {
      button.addEventListener('click', () => {
        const swiperDiv = button.closest('.card-host')?.querySelector('.swiper');
        if(swiperDiv){
          //slide to right index slide
          swiperDiv.swiper.slideTo(button.dataset.index);
        }
      });

      button.classList.add('binded');
    });
  }
}

export { highlighExperienceGalleryItem, handleExperienceGalleryItemClicked }
