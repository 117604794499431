import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

class Dob {
  constructor(wrapper = document) {
    this.wrapper = wrapper.querySelector('#dob-wrapper');

    if(this.wrapper){
      this.day = this.wrapper.querySelector("[name='dob_day']");
      this.month = this.wrapper.querySelector("[name='dob_month']");
      this.year = this.wrapper.querySelector("[name='dob_year']");
      this.inputs = [this.day, this.month, this.year];

      if(this.day && this.month && this.year){
        // this.inputs.forEach(input => {
        //   if(input.classList.contains('order-1')) this.first = input;
        //   else if(input.classList.contains('order-2')) this.second = input;
        //   else if(input.classList.contains('order-3')) this.third = input;
        // });

        //init tooltips on inputs
        this.initTippy();

        this.initKeyUpEvents();
      }
    }
  }

  initKeyUpEvents(){
    this.inputs.forEach(input => {
      input.addEventListener('keyup', (e) => {
        if(e.key === 'ArrowLeft' || e.key === 'ArrowRight') return false;

        //add a zero for day/month
        if((e.key > 1 && input.dataset.type == 'month' && input.value.length < 2) || (e.key > 2 && input.dataset.type == 'day' && input.value.length < 2)){
          input.value = "0" + e.key;
          this.focusNextInput(input);
        }

        //go to next input if current one is full
        this.focusNextInput(input, true);
      });

      input.addEventListener('keydown', (e) => {
        if(e.key === 'ArrowLeft' || e.key === 'ArrowRight') return false;

        //if backspace is pressed and input is empty > go back
        if(e.key === 'Backspace' || e.code === 'Backspace'){
          this.focusPreviousInput(input);
        }
      });
    });
  }

  focusNextInput(input, withDelay = false){
    if(input.hasAttribute('data-next') && input.value.length == input.dataset.maxLength){
      const next = this.wrapper.querySelector("[" + input.dataset.next + "]");
      if(next && !withDelay) next.focus();
      else if(next){
        window.setTimeout(() => {
          next.focus();
        }, 100);
      }
    }
  }

  focusPreviousInput(input){
    if(input.hasAttribute('data-previous') && input.value.length === 0){
      const previous = this.wrapper.querySelector("[" + input.dataset.previous + "]");
      if(previous) previous.focus();
    }
  }

  initTippy(){
    tippy('[data-tippy-dob]', {
      trigger: 'mouseenter focus',
      content: (reference) => reference.getAttribute('data-tippy-dob')
    });
  }
}

export default Dob;
