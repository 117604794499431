import Message from "../Message";
import { ajax } from "../utils";

class Referral {
  constructor() {
    this.wrapper = document.getElementById("referral-section");
    if (this.wrapper) {
      this.initMailSending();
      this.initEnterToSendMail();
      this.initCopyLink();
      this.initResendInvitEmail();
    }
  }

  initMailSending(){
    const sendButtons = document.querySelectorAll('.send-referral-mail');
    if(sendButtons){
      sendButtons.forEach(button => {
        const wrapper = button.closest('.send-referral-mail-wrapper');
        if(wrapper){
          const emailInput = wrapper.querySelector('input');
          if(emailInput){
            button.addEventListener('click', () => {
              let invitationEmail = emailInput.value;
              if(invitationEmail === '') return false;

              if(!emailInput.checkValidity()){
                Message.error(this.wrapper.dataset.incorrectEmail);
                return false;
              }

              let formData = new FormData();
              formData.append('email', invitationEmail);
              formData.append('identifier', this.wrapper.dataset.identifier);

              ajax(this.wrapper.dataset.url, () => {
                Message.success(this.wrapper.dataset.mailSent);

                //clear the email input
                emailInput.value = '';
              }, 'POST', formData,
              { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content },
              true, false);
            });
          }
        }
      });
    }
  }

  initEnterToSendMail(){
    const emailInput = this.wrapper.querySelector('#invit-email');
    const sendEmailButton = this.wrapper.querySelector('#invit-send-button');
    if(emailInput && sendEmailButton){
      emailInput.addEventListener('keydown', (e) => {
        if(e.keyCode == 13 || e.which == 13){
          sendEmailButton.dispatchEvent(new Event('click'));
        }
      });
    }
  }

  initCopyLink(){
    const copyLink = this.wrapper.querySelector('.copy-link');
    if(copyLink){
      const wrapper = copyLink.closest('.copy-wrapper');
      if(wrapper){
        const link = wrapper.querySelector('.referral-link');
        if(link){
          copyLink.addEventListener('click', () => {
            const el = document.createElement('textarea');
            el.value = link.textContent;
            document.body.appendChild(el);
            el.select();
            el.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(el);

            copyLink.textContent = copyLink.dataset.copied;

            window.setTimeout(() => {
              copyLink.textContent = copyLink.dataset.copy;
            }, 2000);
          });
        }
      }
    }
  }

  initResendInvitEmail(){
    const resendButtons = this.wrapper.querySelectorAll('.resend-invite');
    if(resendButtons){
      resendButtons.forEach(button => {
        button.addEventListener('click', () => {
          //make sure to disable the button to prevent spam
          button.disabled = true;
          // window.setTimeout(() => button.disabled = false, 3000);

          let formData = new FormData();
          formData.append('email', button.dataset.email);
          formData.append('resend', true);

          ajax(this.wrapper.dataset.url, () => {
            Message.success(this.wrapper.dataset.mailSent);

            button.textContent = button.dataset.inviteSent;
            button.classList.add('cursor-not-allowed');
          }, 'POST', formData,
          { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content },
          true, false);
        });
      });
    }
  }
}

export default Referral;
