const initMobileMenu = () => {
  const btnOpenMobileNav = document.getElementById('open-mobile-nav');
  const mobileNav = document.getElementById('mobile-fullscreen-nav');
  if (btnOpenMobileNav && mobileNav) {
    btnOpenMobileNav.addEventListener('click', function () {
      if (!mobileNav.classList.contains('active')) {
        mobileNav.classList.add('active');
      }
    });
    const closeMobileNav = mobileNav.querySelector('button.close');
    if (closeMobileNav) {
      closeMobileNav.addEventListener('click', function () {
        mobileNav.classList.remove('active');
      });
    }
  }
}

export { initMobileMenu }
