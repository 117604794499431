import { isElementInViewport } from "../utils";

const initExcellenceAwardSpinVisibility = () => {
  const withExcellenceIconElt = document.querySelector('.with-excellence-icon');
  if(withExcellenceIconElt){
    window.addEventListener('scroll', () => {
      withExcellenceIconElt.classList.toggle('animation-paused', !isElementInViewport(withExcellenceIconElt));
    });
  }
}

export { initExcellenceAwardSpinVisibility }
