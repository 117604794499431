const initOffuscation = (container = document) => {
  const links = container.querySelectorAll('[data-redirect-go]');
  if(links){
    links.forEach((link) => {
      // Left mouse button
      let event = 'click';
      if(link.hasAttribute('data-click-event')) event = link.dataset.clickEvent;

      link.addEventListener(event, (e) => {
        const url = removeAmp(decodeURIComponent(window.atob(link.dataset.redirectGo)));
        if (
            (e && (e.which == 2 || e.button == 4 ))
            || e.ctrlKey
            || (link.hasAttribute('target') && link.getAttribute('target') === '_blank')
          ) {
          window.open(url);
        }
        else {
          window.location.href = url;
        }
      });

      // Middle mouse button
      link.addEventListener('mousedown', event => {
        // Remove scroll on middle click
        if (event.button === 1) event.preventDefault();
      });

      // Middle mouse button
      link.addEventListener('mouseup', event => {
        const url = removeAmp(decodeURIComponent(window.atob(link.dataset.redirectGo)));

        // Open link in new tab on middle click
        if (event.button === 1) window.open(url);
      });
    });
  }
}

const removeAmp = (url) => {
  return url.replace(/&amp;/g, '&');
}

export { initOffuscation }
