class Drawer {
    static init(callback = function(){}) {
      // Get all drawers
      const drawers = document.querySelectorAll('x-drawer:not(.binded)');
      if (drawers.length > 0) {
        drawers.forEach(drawer => {
          this.initDrawer(drawer);

          window.setTimeout(() => {
            if(drawer.hasAttribute('data-open-on-load')){
              this.openDrawer(drawer.querySelector('.drawer'));
            }
          }, 1000);
        });

        // Close all drawers on click on elements with this attribute
        const eltsClosingAllDrawers = document.querySelectorAll('[data-close-drawers-on-click]');
        if(eltsClosingAllDrawers) {
          eltsClosingAllDrawers.forEach(elt => {
            elt.addEventListener('click', () => {
              this.closeAllDrawers();
            });
          });
        }
      }

      //perform any callback after drawer init is done
      callback();
    }

    static initDrawer(drawerContainer) {
        const drawerHTML = this.createDrawerHTML(drawerContainer);
        drawerContainer = this.replaceTemplateWithDrawer(drawerContainer, drawerHTML);

        // get all open buttons
        const openButtons = document.querySelectorAll(`[data-drawer-tag="${drawerContainer.dataset.buttonTag}"]`);
        // get all close buttons
        const closeButtons = drawerContainer.querySelectorAll(".closeDrawer");
        // get drawer and overlay
        const drawer = drawerContainer.querySelector(".drawer");
        const overlay = drawerContainer.querySelector(".overlay-drawer");

        // Check if everything is here
        // if (openButtons.length === 0) return console.error('Missing open buttons for drawer');
        // if (closeButtons.length === 0) return console.error('Missing close buttons for drawer');
        if (!drawer) return console.error('Missing drawer for drawer');
        if (!overlay) return console.error('Missing overlay for drawer');

        const direction = drawerContainer.dataset.direction || 'right';
        const width = drawerContainer.dataset.width || 'w-full lg:w-11/12';
        if (drawerContainer.dataset.notFullHeight === 'true') {
            drawer.classList.add('not-full-height');
        }
        if (drawerContainer.dataset.fullHeight === 'true') {
            drawer.classList.add('full-height');
        }

        drawer.classList.add(direction);
        drawer.classList.add(...width.split(' '));
        this.addEventListeners(openButtons, closeButtons, overlay, drawer);
        drawerContainer.classList.remove('hidden');

        drawer.closest('x-drawer').classList.add('binded');
    }

    static createDrawerHTML(drawerContainer) {
        let whiteBgCloseButton = "";
        if (drawerContainer.dataset.whiteBgCloseButton === 'true') {
          whiteBgCloseButton = "bg-white hover:bg-gray-100 rounded-full w-8 h-8 flex justify-center items-center p-1";
        }

        let buttonClose = drawerContainer.dataset.defaultCloseButton === 'false'
            ? ''
            : `
                <button type="button" class="closeDrawer absolute top-0 right-0 mr-3 mt-3 focus:outline-none ${whiteBgCloseButton ? whiteBgCloseButton : "p-2"}" aria-label="Close drawer">
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                    </svg>
                </button>
            ` ;


        // let swiperToClose = '';
        // if (
        //   drawerContainer.dataset.fullHeight !== 'true'
        //   && /iPhone/i.test(navigator.userAgent)
        // ) {
        //   swiperToClose = `
        //     <div class="swipeable flex md:hidden justify-center items-center py-3 px-5 absolute top-0 w-full max-w-xs">
        //       <div class="w-24 h-1 bg-gray-300 rounded-full"></div>
        //     </div>
        //   `;
        // }

        let padding = drawerContainer.dataset.noPadding === 'true' ? '' : 'pt-16 standard-px-drawer';
        let paddingBottom = drawerContainer.dataset.noPaddingBottom === 'true' ? '' : 'pb-10';
        if (drawerContainer.dataset.noPadding === 'true') {
            paddingBottom = '';
        }
        let bgColor = drawerContainer.hasAttribute('data-bg-color') ? drawerContainer.dataset.bgColor : 'bg-white';
        let customClass = drawerContainer.dataset.customClass || '';

        //ensure that visitor has a smooth scroll when clicking date/visitor buttons
        let hasDateVisitorComponent = drawerContainer.hasAttribute('data-has-date-visitor-component') ? 'date-visitor-scroll-wrapper smooth-scroll' : '';

        let drawerHTML = `
            <div class="drawer fixed max-h-full ${bgColor} z-50 overflow-y-auto ${customClass} ${paddingBottom} ${padding} ${hasDateVisitorComponent}">
                ${buttonClose}
                ${drawerContainer.innerHTML}
            </div>
            <div class="overlay-drawer"></div>
        `;

        return drawerHTML;
    }

    static replaceTemplateWithDrawer(drawerContainer, drawerHTML) {
        // Replace the template with the drawer
        drawerContainer.innerHTML = drawerHTML;
        return drawerContainer;
    }

    static addEventListeners(openButtons, closeButtons, overlay, drawer) {
        // Add event listeners
        openButtons.forEach(openButton => {
            this.bindOpenButton(openButton, drawer);
        });

        // Close drawer on close button click
        closeButtons.forEach(closeButton => {
            closeButton.addEventListener("click", () => {
                this.closeDrawer(drawer);
            });
        });

        // Close drawer on overlay click
        overlay.addEventListener("click", () => {
            this.closeDrawer(drawer);
        });

        // Close drawer on escape key
        document.addEventListener('keydown', (event) => {
            if (drawer.classList.contains('opened') && event.key === "Escape") {
                this.closeDrawer(drawer);
            }
        });

        // if (/iPhone/i.test(navigator.userAgent)) {
        //   this.initEventSwipeToClose(drawer);
        // }
    }

    /**
     * Binds the open button passed in parameter to a drawer.
     *
     * @param {HTMLElement} openButton - The button element that triggers the opening of the drawer.
     * @param {HTMLElement} drawer - The drawer element that is being opened.
     */
    static bindOpenButton(openButton, drawer) {
      openButton.addEventListener("click", () => {
          this.openDrawer(drawer);
      });

      openButton.classList.add('open-drawer-binded');
    }

    static bindOpenButtons(wrapper = document){
      const openButtons = wrapper.querySelectorAll('[data-drawer-tag]:not(.open-drawer-binded)');
      if(openButtons.length > 0){
        openButtons.forEach(openButton => {
          const drawer = document.querySelector(`[data-button-tag="${openButton.dataset.drawerTag}"] .drawer`);
          if(drawer){
            Drawer.bindOpenButton(openButton, drawer);
          }
        });
      }
    }

    static openDrawer(drawer){
      drawer.classList.add('opened');

      // Wait for the drawer to be opened before increasing the opacity
      drawer.style.opacity = 1;

      document.body.classList.add("no-background-scroll");
    }

    /**
     * Close the drawer passed in parameter.
     *
     * @param {HTMLElement} drawer - The drawer element to be closed.
     */
    static closeDrawer(drawer) {
      if (!drawer) return console.error('Missing drawer for drawer');
      // Close the drawer
      drawer.classList.remove('opened');

      // Wait for the drawer to be closed before reducing the opacity
      setTimeout(() => {
        drawer.style.opacity = 0;
      }, 300);

      !this.hasDrawerOpen() ? document.body.classList.remove("no-background-scroll") : '';
    }

    /**
     * Closes all drawers on the page.
     *
     * @param {None} None - This function does not take any parameters.
     * @return {None} None - This function does not return any value.
     */
    static closeAllDrawers(){
      const drawers = document.querySelectorAll('.drawer');
      if(drawers){
        drawers.forEach(drawer => {
          this.closeDrawer(drawer);
        });

        document.body.classList.remove("no-background-scroll");
      }
    }

    /**
     * Check if a drawer is opened on the page.
     *
     * @return {boolean} True if a drawer is opened, false otherwise.
     */
    static hasDrawerOpen() {
      return !!document.querySelector('.drawer.opened');
    }

    // static initEventSwipeToClose(drawer) {
    //   const swipeable = drawer.querySelector('.swipeable');
    //   const overlay = drawer.closest('x-drawer').querySelector('.overlay-drawer');

    //   if (swipeable && overlay) {
    //     // Initialize the variable to store the initial touch position
    //     let yDown = null;

    //     const handleTouchStart = (e) => {
    //       const firstTouch = e.touches[0];
    //       yDown = firstTouch.clientY;
    //     };

    //     // During the swipe gesture, move the drawer
    //     const handleTouchMove = (e) => {
    //       if (!yDown) return;

    //       const yUp = e.touches[0].clientY;
    //       let yDiff = yDown - yUp;

    //       if (yDiff <= 0) {
    //         yDiff = yDiff * -1;
    //         drawer.style.transform = `translateY(${yDiff}px)`;
    //       }
    //     };

    //     // When the swipe gesture ends, close the drawer if the swipe is long enough
    //     const handleTouchEnd = (e) => {
    //       if (!yDown) return;

    //       const yUp = e.changedTouches[0].clientY;
    //       const yDiff = yDown - yUp;

    //       // Close the drawer if the swipe is long enough (more than 1/7 of the drawer height)
    //       if (Math.abs(yDiff) > drawer.clientHeight / 7) {
    //         this.closeDrawer(drawer);
    //         setTimeout(() => {
    //           drawer.style.transform = '';
    //         }, 160);
    //       } else {
    //         drawer.style.transform = '';
    //       }

    //       yDown = null;
    //     };

    //     // Add event listeners to the overlay and the swipeable element
    //     overlay.addEventListener('touchstart', handleTouchStart);
    //     overlay.addEventListener('touchmove', handleTouchMove);
    //     overlay.addEventListener('touchend', handleTouchEnd);

    //     swipeable.addEventListener('touchstart', handleTouchStart);
    //     swipeable.addEventListener('touchmove', handleTouchMove);
    //     swipeable.addEventListener('touchend', handleTouchEnd);

    //     // Close the drawer when swiping down and the content is at the top
    //     drawer.addEventListener('touchstart', (e) => {
    //       if (drawer.scrollTop === 0) {
    //         handleTouchStart(e);
    //       }
    //     });
    //     drawer.addEventListener('touchmove', handleTouchMove);
    //     drawer.addEventListener('touchend', (e) => {
    //       if (yDown && e.changedTouches[0].clientY > yDown) {
    //         handleTouchEnd(e);
    //       }
    //     });
    //   }
    // }
}

export default Drawer;

// ? How to use it
{/*
// ? Put this in the javascript file
Drawer.init();

// ? Put this button where you want
<button type="button" data-drawer-tag="MyUniqueTag">
    My button name
</button>

// ? Put this at the end of the page
// Class hidden and data-button-id are required
// data-width is optional (default: w-11/12)
// data-direction is optional (default: bottom; other: left, top, right)
// data-default-close-button is optional (default: true), if false you have to add a button with the class .closeDrawer

<x-drawer class="hidden" data-button-tag="MyUniqueTag" data-direction="left" data-width="w-full md:w-3/5 lg:w-1/3" data-default-close-button="false" data-not-full-height="false">
    <div class="bg-white">
        <div class="flex justify-between items-center border-b border-gray-200">
            <h3 class="text-lg font-semibold">Bonjour</h3>
        </div>
        <div class="px-4 py-3">
            <p class="text-sm leading-5 text-gray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus,
                cum deleniti sunt in labore nihil quod quibusdam expedita nemo.
            </p>
        </div>
    </div>
</x-drawer>
*/}
