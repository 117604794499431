const initInfoCards = () => {
  const closeInfoCards = document.querySelectorAll('.close-info-card');
  if(closeInfoCards){
    closeInfoCards.forEach(function(close){
      close.addEventListener('click', function(){
        close.parentNode.remove();
      });
    });
  }
}

export { initInfoCards }
