const removeFlashMessage = () => {
    // Remove flash message after 4 seconds
    let alert = document.querySelector('.message');
    if (alert && (alert.classList.contains('success') || alert.classList.contains('error'))) {
        setTimeout(() => {
            alert.remove();
        }, 4000);
    }
}

export default removeFlashMessage;