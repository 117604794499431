const initBtnLoading = () => {
  const forms = document.querySelectorAll('form');
  if(forms){
    forms.forEach((form) => {
      const submitButtons = form.querySelectorAll('button[type="submit"]:not(.no-loader)');
      if(submitButtons){
        form.addEventListener('submit', (event) => {
          if(event.submitter && event.submitter.nodeName === 'BUTTON' && !event.submitter.hasAttribute('type')){
            return false; //workaround for onboarding date of birth datepicker
          }

          if(form.checkValidity() &&
           (!form.classList.contains('need-checks') || (form.classList.contains('need-checks') && form.classList.contains('checks-validated')))){
            submitButtons.forEach(button => {
              button.classList.add('loading');
              button.disabled = true;
            });
          }
        });
      }
    });
  }
}

export { initBtnLoading }
