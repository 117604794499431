import { addLoadingStatusOnSearchButton } from "../common/init-search-bar";
import { ajax } from "../utils";
import addLoadingStatusSearchOnMobile from "./addLoadingStatusSearchOnMobile";
import { initOffuscation } from "./link-offuscation";

const initBehaviorForDropdownRecentlyViewed = () => {
    let dropdownSuggestionsHostDestination = document.querySelectorAll('.dropdown-suggestions-host-destination');
    if (!dropdownSuggestionsHostDestination.length > 0) return;

    let currentUri = window.location.pathname;

    let url = dropdownSuggestionsHostDestination[0].dataset.url + "?uriFrom=" + currentUri;

    if (currentUri.includes('hosts')) {
        let hostId = dropdownSuggestionsHostDestination[0].dataset.hostId;
        if (hostId) {
            url += "&hostId=" + hostId
        }
    } else if (currentUri.includes('countries')) {
        let countryId = dropdownSuggestionsHostDestination[0].dataset.countryId;
        if (countryId) {
            url += "&countryId=" + countryId
        }
    } else if (currentUri.includes('appellations')) {
        let appellationId = dropdownSuggestionsHostDestination[0].dataset.appellationId;
        if (appellationId) {
            url += "&appellationId=" + appellationId
        }
    }

    ajax(url,
        (data) => {
            // Create a div to parse the html
            let div = document.createElement('div');
            div.innerHTML = data;

            let dataRecentlyViewed = div.querySelector('.recently-viewed-hosts');
            let dataTopWineries = div.querySelector('.top-wineries');
            let dataTopDestinations = div.querySelector('.top-destinations');
            dropdownSuggestionsHostDestination.forEach(dropdown => {
                updateDropdownContent(dropdown, dataRecentlyViewed, dataTopWineries, dataTopDestinations);
                addEventListenersToLinks(dropdown);
                manageDropdownDisplay(dropdown);
                initOffuscation(dropdown);
            });
        },
        'GET',
        {},
        {
            "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
        },
        true,
        false
    );
}

const updateDropdownContent = (dropdown, dataRecentlyViewed, dataTopWineries, dataTopDestinations) => {
    if (dataRecentlyViewed) {
        dropdown.querySelector('.recently-viewed-hosts').innerHTML = dataRecentlyViewed.innerHTML;
    }

    if (dataTopWineries) {
        dropdown.querySelector('.top-wineries').innerHTML = dataTopWineries.innerHTML;
    }

    if (dataTopDestinations) {
        dropdown.querySelector('.top-destinations').innerHTML = dataTopDestinations.innerHTML;
    }
};

const addEventListenersToLinks = (dropdown) => {
    // Event listeners for recently viewed dropdown, add loading status on search button
    dropdown.querySelectorAll('span[data-redirect-go]').forEach(link => {
        link.addEventListener('mousedown', () => {
            // On click on a link, add the host name in the search bar and add loading status on search button
            let hostName = link.querySelector('span')?.innerText;
            if (!hostName) return;

            // Add host name in all inputs search bar
            let allSearchBars = document.querySelectorAll('.search-bar');
            allSearchBars.forEach(searchBar => {
                let inputSearch = searchBar.querySelector('[id$="_search_bar"]');
                if (inputSearch) inputSearch.value = hostName;
            });

            addLoadingStatusOnSearchButton()
            addLoadingStatusSearchOnMobile()
        });
    });

    // Event listener for around me button
    dropdown.querySelector('button.aroundMe')?.addEventListener('mousedown', () => {
        addLoadingStatusSearchOnMobile()
    });
};

const manageDropdownDisplay = (dropdown) => {
    let searchBar = dropdown.closest('.search-bar');
    if(searchBar) {
        let inputSearch = searchBar.querySelector('[id$="_search_bar"]');
        if (inputSearch) {
            inputSearch.addEventListener('focus', () => dropdown.classList.toggle('show', true));
            inputSearch.addEventListener('blur', (e) => {
                if (dropdown.contains(e.relatedTarget)) return;
                setTimeout(() => {
                    dropdown.classList.toggle('show', false);
                }, 170); // time to click on a link before the dropdown closes
            });
        }
    }
};

export { initBehaviorForDropdownRecentlyViewed };
