const initTabs = () => {
  const wrappers = document.querySelectorAll('.tab-wrapper:not(.binded)');
  if(wrappers){
    wrappers.forEach((wrapper) => {
      const tabs = wrapper.querySelectorAll('li[data-tab]');
      const contents = wrapper.querySelectorAll('[data-tab-content]');
      if(tabs && contents){
        tabs.forEach((tab) => {
          if(!tab.classList.contains('unavailable')){
            tab.addEventListener('click', () => {
              const activeTab = wrapper.querySelector('.active[data-tab]');
              if(activeTab){
                activeTab.classList.remove('active');
              }
              tab.classList.add('active');

              //Hide content of other tabs
              hideContents(contents);

              //Show content of tab clicked
              const currentContent = wrapper.querySelector("[data-tab-content='" + tab.dataset.tab + "']");
              currentContent.classList.remove('hidden');
            });
          }
        });
      }

      wrapper.classList.add('binded');
    });
  }
}

const hideContents = (contents) => {
  contents.forEach((content) => {
    content.classList.add('hidden');
  });
}

export { initTabs }
