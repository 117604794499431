const closeRemindOnboardingNavbar = () => {
  const navbar = document.querySelector('#remind_onboarding');
  const closeNavbarButton = document.querySelector('#remove-remind-onboarding-navbar');
  if(navbar && closeNavbarButton){
    closeNavbarButton.addEventListener('click', () => {
      navbar.remove();

      const navbarsWithMargin = document.querySelectorAll('.mt-remind-onboarding');
      if(navbarsWithMargin){
        navbarsWithMargin.forEach(navbar => {
          navbar.classList.remove('mt-remind-onboarding');
        });
      }
    });
  }
}

export { closeRemindOnboardingNavbar }
