const initSearchBar = (wrapperElement) => {
    if (!wrapperElement) return;

    let containerSearchBar = wrapperElement.querySelector('.container-search-bar');
    let searchBar = wrapperElement.querySelector('.search-bar');
    let searchButton = wrapperElement.querySelector('.search-button');
    // ends with _search_bar"
    let inputSearch = wrapperElement.querySelector('[id$="_search_bar"]');

    if (!searchBar || !searchButton || !inputSearch) return;

    // Search button
    initSearchButton(searchBar, searchButton, containerSearchBar, wrapperElement, inputSearch);

    // Event listeners for input search
    document.addEventListener('keydown', (event) => {
        if (event.key === "Escape" && inputSearch === document.activeElement) {
            if (containerSearchBar) {
                eventFocus(false, containerSearchBar, wrapperElement);
            }
            inputSearch.blur();
        }
        if (event.key === "Enter" && inputSearch === document.activeElement) {
            searchButton.click();
        }
    });

    if (!searchBar.classList.contains('search-bar-home') && containerSearchBar) {
        // Event listeners input search
        inputSearch.addEventListener('click', () => eventFocus(true, containerSearchBar, wrapperElement));

        let overlay = document.querySelector('.nav-overlay');
        if (overlay) {
            overlay.addEventListener('click', () => eventFocus(false, containerSearchBar, wrapperElement));
        }

        wrapperElement.addEventListener('click', (e) => {
            let firstDiv = document.querySelector('#nav-bar > div');
            if (wrapperElement.classList.contains("focused") && (e.target === firstDiv || e.target === wrapperElement)) {
                eventFocus(false, containerSearchBar, wrapperElement);
            }
        });

        // Listener for scroll
        window.addEventListener('scroll', () => {
            if (containerSearchBar.classList.contains('focused')) {
                eventFocus(false, containerSearchBar, wrapperElement);
                inputSearch.blur();
            }
        });
    }
}

const eventFocus = (onFocus, containerSearchBar, wrapperElement = null) => {
    containerSearchBar.classList.toggle('focused', onFocus);
    wrapperElement.classList.toggle('focused', onFocus);
    // document.body.classList.toggle('no-background-scroll', onFocus);
}

const addLoadingStatusOnSearchButton = () => {
    const searchButtons = document.querySelectorAll('.search-button');
    searchButtons.forEach(searchButton => {
        searchButton.classList.add("loading");
        searchButton.disabled = true;
        searchButton.querySelector('i')?.classList.add("invisible");
    });
}

const initSearchButton = (searchBar, searchButton, containerSearchBar, wrapperElement, inputSearch) => {
    searchButton.addEventListener('click', () => {
        let inputValue = inputSearch.value;
        if (inputValue.trim() === '') {
            inputSearch.focus();

            if (!searchBar.classList.contains('search-bar-home')) {
                eventFocus(true, containerSearchBar, wrapperElement);
            }
        } else {
            // add loading
            searchButton.classList.add("loading");
            searchButton.disabled = true;
            searchButton.querySelector('i')?.classList.add("invisible");

            // redirect to search page
            window.location.href = searchButton.dataset.url + inputValue;
        }
    });
}

export { initSearchBar, addLoadingStatusOnSearchButton };